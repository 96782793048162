import { Card, Button } from 'antd';
import { NormalProgrammeComponent, ExportModal } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import ApplyAfterSales from '../applyAfterSales';
import { TopTab, OrderGrid } from '../components';
import { CourierInfoDrawer } from './courierInfoDrawer';
import styles from './index.less';
import { ReturnLogisticModal } from './returnLogisticsModal';
import { Store } from './store';

const store = new Store();

const AfterSaleManage = observer(() => {
  const { programme, afterSaleStatusList, activeStatus, handleTabChange, orderGridStore, courierInfoStore, returnLogisticsStore, applyAfterSaleStore, exportStore } = store;
  useEffect(() => {
    return () => {
      store.timeInterval = null;
    };
  }, []);
  return (
    <div className={styles.page}>
      <TopTab
        activeKey={activeStatus}
        list={afterSaleStatusList}
        onTabChange={handleTabChange}
      />
      <Card className={styles.card}>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => {
            applyAfterSaleStore.changeVisible(true);
          }}
          size="small"
          type="primary"
        >
          批量申请售后
        </Button>
        <Button
          className={styles.exportBtn}
          onClick={() => {
            store.exportOrder();
          }}
          size="small"
        >
          导出售后单
        </Button>
      </div>
      <div className={styles.orderWrapper}>
        <OrderGrid store={orderGridStore}/>
      </div>
      {/* 物流信息抽屉 */}
      <CourierInfoDrawer store={courierInfoStore}/>
      {/* 填写退货物流弹窗 */}
      <ReturnLogisticModal store={returnLogisticsStore}/>
      {/* 批量申请售后 */}
      <ApplyAfterSales store={applyAfterSaleStore}/>
      {/* 导出弹窗 */}
      <ExportModal store={exportStore}/>
    </div>
  );
});

export default AfterSaleManage;
