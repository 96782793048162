import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
//import { phoneReg } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TimeRemain } from '../constant';
import styles from './index.less';
import type { ReturnLogisticsStore } from './store';

const phoneReg = /\d{10,11}/;
const { Item } = Form;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export const ReturnLogisticModal: React.FC<{ store: ReturnLogisticsStore; }> = observer((props) => {
  const {
    formRef,
    visible,
    closeModal,
    courierOptions,
    receivedAddress,
    receivedUser,
    receivedMobile,
    submitCoureirInfo,
    status,
    auditTimeStr,
  } = props.store;
  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      maskClosable={false}
      onCancel={closeModal}
      onOk={submitCoureirInfo}
      open={visible}
      title="填写退回物流"
      width={400}
    >
      <div className={styles.returnedAddressWrapper}>
        <div className={styles.tipWrap}>
          <p>
            · 请按以下地址寄回并填写寄回信息，不支持到付
          </p>
          <p>
            · 请确认原吊牌、外包装袋无遗失，且不影响二次销售
          </p>
          {status === 1 && auditTimeStr ? (
            <p>
              <TimeRemain
                auditTimeStr={auditTimeStr}
                suffix="·"
              />
            </p>
          ) : null}
        </div>
        <Row>
          <Col span={6}>
            退货寄回地址：
          </Col>
          <Col
            className={styles.colStyle}
            span={18}
          >
            <div>
              {receivedUser}
            </div>
            <div>
              {receivedMobile}
            </div>
            <div>
              {receivedAddress}
            </div>
            <CopyToClipboard
              onCopy={() => {
                message.success('复制成功');
              }}
              text={receivedAddress}
            >
              <Button className={styles.copyBtn}>
                复制
              </Button>
            </CopyToClipboard>
          </Col>
        </Row>

      </div>
      <Form
        {...formLayout}
        ref={formRef}
      >
        <Item
          label="物流公司"
          name="courierCompany"
          rules={[{ required: true }]}
        >
          <Select
            options={courierOptions}
            showSearch
          />
        </Item>
        <Item
          label="物流单号"
          name="courierOrderNo"
          rules={[{ required: true }]}
        >
          <Input/>
        </Item>
        <Item
          label="联系手机"
          name="mobile"
          rules={[
            {
              pattern: phoneReg,
              message: '请输入正确的手机号',
            },
          ]}
        >
          <Input/>
        </Item>
      </Form>
    </Modal>
  );
});
