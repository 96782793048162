import { Popover } from 'antd';
import type { FilterItemOptions } from 'egenie-utils';
import { ImgFormatter } from 'egenie-utils';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import noPic from '../../assets/images/noPic.png';
import { CopyIcon } from '../components';
import type { EachColumnProps, EachHeaderProps } from '../components/orderGrid/store';
import styles from './index.less';
import type { Store } from './store';

// 查询项
export const FILTER_ITEMS = (context: Store): FilterItemOptions[] => {
  return [
    {
      type: 'input',
      field: 'returnOrderNo',
      label: '售后编号',
      isMultipleSearch: true,
    },
    {
      type: 'select',
      field: 'type',
      label: '售后类型',
      data: AfterSaleTypeOptions,
    },
    {
      type: 'select',
      field: 'status',
      label: '售后状态',
    },
    {
      type: 'input',
      field: 'saleOrderNo',
      label: '订单编号',
    },
    {
      type: 'input',
      field: 'goodsSkuNo',
      label: 'SKU编码',
    },
    {
      type: 'input',
      field: 'courierOrderNo',
      label: '退回单号',
    },
    {
      type: 'dateRange',
      field: 'applyTime',
      label: '申请时间',
    },
    {
      type: 'select',
      field: 'reasonCode',
      label: '售后原因',
    },
  ];
};

// 订单表格列信息
export const GRID_COLUMNS = (context: Store): EachColumnProps[] => {
  return [
    {
      width: 30,
      name: '商品信息',
      merge: false,
      detailFormatter: (detail, row) => {
        const {
          mainPicUrl,
          goodsName,
          goodsSpec,
          goodsSkuNo,
        } = detail;
        return (
          <div className={styles.productInfo}>
            {
              mainPicUrl ? (
                <ImgFormatter
                  height={56}
                  value={mainPicUrl}
                  width={56}
                />
              ) : (
                <img
                  className={styles.imgStyle}
                  src={noPic}
                />
              )
            }
            <div className={styles.productText}>
              <div
                className={styles.productName}
                onClick={() => {
                  window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${detail.goodsId}`);
                }}
              >
                {goodsName || ' '}
                <i className="icon-sq"/>
              </div>
              <div>
                {goodsSpec}
              </div>
              <div>
                SKU编码：
                {goodsSkuNo}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      width: 10,
      name: '金额(元)',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <>
            ¥
            {' '}
            {row.totalAmount}
          </>
        );
      },
    },
    {
      width: 10,
      name: '数量',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div>
            ×
            {' '}
            {detail.applyNum}
          </div>
        );
      },
    },
    {
      width: 140,
      name: '售后状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            {context.afterSaleStatusDict[row.status]}
          </div>
        );
      },
    },
    {
      width: 140,
      name: '售后原因',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            {row.reason}
          </div>
        );
      },
    },
    {
      width: 140,
      name: '物流状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <>
            <span className={styles.returnGoods}>
              退货
            </span>
            {CourierTypeDict[row.courierType]}
            {row.courierType > 0 && (
              <i
                className={`icon-icon_kdgs ${styles.icon}`}
                onClick={() => {
                  context.courierInfoStore.onDrawerOpen(row.mallReturnOrderId);
                }}
              />
            )}
            {(row.status === 1 && row.auditTimeStr) ? (
              <div style={{ color: '#fb071f' }}>
                <TimeRemain
                  auditTimeStr={row.auditTimeStr}
                  suffix="*"
                />
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      width: 140,
      name: '操作',
      merge: true,
      detailFormatter: (row) => {
        return (
          <>
            <a
              className={styles.operate}
              onClick={() => {
                console.log(`/egenie-ts-vogue/afterSaleOrderDetail/index?mallReturnOrderId=${row.mallReturnOrderId}`);
                window.top.egenie.openTab(`/egenie-ts-vogue/afterSaleOrderDetail/index?mallReturnOrderId=${row.mallReturnOrderId}`, 'afterSaleOrderDetail', '售后订单详情');
              }}
            >
              售后详情
            </a>
            {/* 待门店退货这一个状态可以填写退货物流*/}
            {
              row.status === 1 && (
                <a
                  className={styles.operate}
                  onClick={() => {
                    context.returnLogisticsStore.openModal({
                      receivedUser: row.receivedUser,
                      receivedMobile: row.receivedMobile,
                      receivedAddress: row.receivedAddress,
                      mallReturnOrderId: row.mallReturnOrderId,
                      status: row.status,
                      auditTimeStr: row.auditTimeStr,
                    });
                  }}
                >
                  填写退回物流
                </a>
              )
            }
            {/* 退款成功后不可撤销申请 */}
            {row.status < 4 && (
              <a
                className={styles.operate}
                onClick={
                  () => {
                    context.cancelApplication(row.mallReturnOrderId);
                  }
                }
              >
                撤销申请
              </a>
            )}
          </>
        );
      },
    },
  ];
};

// 每一项的标题头
export const ITEM_HEADER_COLUMNS = (context: Store): EachHeaderProps[] => {
  return [
    {
      width: '20%',
      key: 'mallReturnOrderNo',
      rowFormatter: (row) => {
        return (
          <>
            <div>
              售后编号：
              {row.mallReturnOrderNo}
            </div>
            <CopyIcon text={row.mallReturnOrderNo}/>
          </>
        );
      },
    },
    {
      width: '20%',
      key: 'saleOrderNos',
      rowFormatter: (row) => {
        return (
          <>
            订单编号：
            {row.saleOrderNos?.length > 1 ? (
              <Popover
                content={(
                  <div className={styles.popContent}>
                    {row.saleOrderNos.join(',')}
                  </div>
                )}
                placement="bottom"
              >
                <span className={styles.code}>
                  {`${row.saleOrderNos[0]},...`}
                </span>
              </Popover>
            ) : row.saleOrderNos.join(',')}
          </>
        );
      },
    },
    {
      width: '20%',
      key: 'applyTime',
      rowFormatter: (row) => {
        return (
          <div>
            申请时间：
            {row.applyTime}
          </div>
        );
      },
    },
    {
      width: '20%',
      key: 'type',
      rowFormatter: (row) => {
        return (
          <div className={styles.afterSaleStatus}>
            {AfterSaleTypeDict[row.type]}
          </div>
        );
      },
    },
  ];
};

// 每一项的标题头右边内容
export const itemHeaderRightContent = (row): React.ReactNode => {
  return (
    <Popover
      content={row.remark}
      placement="bottom"
    >
      <a>
        备注
      </a>
    </Popover>
  );
};

const AfterSaleTypeDict = {
  '1': '仅退款',
  '2': '退货退款',
};

// 物流状态
const CourierTypeDict = {
  '0': '未发货',
  '1': '已发货',
};

const AfterSaleTypeOptions = [
  {
    label: '仅退款',
    value: '1',
  },
  {
    label: '退货退款',
    value: '2',
  },
];

export const getLeftTime = (applyTime: string): string => {
  let timeRemain = '';

  if (!applyTime) {
    return '';
  }
  const endTimeTmp = moment(applyTime)
    .add(7, 'days')
    .format('YYYY-MM-DD HH:mm:ss');
  const leftTime = moment(new Date(endTimeTmp).getTime())
    .diff(moment(new Date().getTime()));
  if (leftTime > 0) {
    const times = leftTime / 1000; // times是剩余时间总的秒数
    const dt = Math.floor(times / 60 / 60 / 24);
    const d = dt < 10 ? `0${dt}` : dt;
    const ht = parseInt(`${times / 60 / 60 % 24}`, 10); // 时
    const h = ht < 10 ? `0${ht}` : ht;
    const mt = parseInt(`${times / 60 % 60}`, 10);
    const m = mt < 10 ? `0${mt}` : mt;
    const st = parseInt(`${times % 60}`, 10);
    const s = st < 10 ? `0${st}` : st;
    timeRemain = `${d}天${h}小时${m}分钟${s}秒`;
  } else {
    timeRemain = '';
  }
  return timeRemain;
};

export const TimeRemain = observer((props) => {
  const {
    auditTimeStr,
    suffix,
  } = props;
  let timeInterval = null;
  const [
    remainTime,
    setRemainTime,
  ] = React.useState('');

  useEffect(() => {
    setRemainTime(getLeftTime(auditTimeStr));
    timeInterval = setInterval(() => {
      const leftTime = getLeftTime(auditTimeStr);
      if (!leftTime) {
        clearInterval(timeInterval);
      }
      setRemainTime(leftTime);
    }, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);
  return remainTime ? (
    <span>
      {suffix}
      请在
      {remainTime}
      内填写退回物流信息，逾期未填写售后申请将自动关闭
    </span>
  ) : null;
});
