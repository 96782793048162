export const api = {
  getStatusList: '/api/mall/rest/return/order/findStatusList', // 售后状态查询
  getAfterSaleOrder: '/api/mall/rest/return/order/findPageByParam', // 查询售后单
  getAfterSaleReasons: '/api/mall/rest/return/order/reasons', // 售后原因查询
  getDetailInfo: '/api/mall/rest/return/order/queryDetailInfo', // 查询售后单详情
  getCourierInfo: '/api/mall/operation/rest/returnOrder/findCourierInfo', // 获取物流信息
  getCourierList: '/api/mall/rest/return/order/couriers', // 查询快递公司列表
  fillLogistics: '/api/mall/rest/return/order/supply/logistics', // 填写退货物流
  cancelAppli: '/api/mall/rest/return/order/cancel', // 撤销申请
};
