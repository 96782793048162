import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { api } from '../api';
import type { ICourierInfo } from '../interface';
import type { Store } from '../store';

export class CourierInfoStore {
  constructor(options) {
    this.parent = options.parent;
  }

  public parent: Store;

  @observable public visible = false;// 是否展示物流信息

  @observable public loading = false;// 抽屉loading

  public courierName = '';// 物流公司

  public courierOrderNo = '';// 运单号

  public returnSendTime = '';// 发货时间

  public totalNum = 0;// 商品数量

  public skuVoList = [];// skuList

  public courierInfoList = [];// 物流信息

  // 关闭抽屉
  @action public onDrawerClose = (): void => {
    Object.assign(this, {
      courierName: '',
      courierOrderNo: '',
      returnSendTime: '',
      totalNum: null,
      skuVoList: [],
      courierInfoList: [],
      visible: false,
      mallReturnOrderId: null,
    });
  };

  // 打开抽屉
  @action public onDrawerOpen = (mallReturnOrderId: number) => {
    this.visible = true;
    this.loading = true;
    request<BaseData<ICourierInfo>>({
      url: api.getCourierInfo,
      method: 'GET',
      params: { mallReturnOrderId },
    }).then((res) => {
      const { courierName, courierOrderNo, returnSendTime, totalNum, skuVoList, courierInfoList } = res.data;
      Object.assign(this, {
        courierName,
        courierOrderNo,
        returnSendTime,
        totalNum,
        skuVoList,
        courierInfoList,
      });
    })
      .finally(() => {
        this.loading = false;
      });
  };
}
