import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { ExportStore, NormalProgramme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import ApplyAfterSaleStore from '../applyAfterSales/store';
import { OrderGridStore } from '../components/orderGrid/store';
import { api } from './api';
import { FILTER_ITEMS, GRID_COLUMNS, ITEM_HEADER_COLUMNS } from './constant';
import { CourierInfoStore } from './courierInfoDrawer/store';
import type { ISaleStatus } from './interface';
import { ReturnLogisticsStore } from './returnLogisticsModal/store';

export class Store {
  constructor() {
    this.getConfigStatus();
    this.programme.handleSearch();
  }

  public courierInfoStore = new CourierInfoStore({ parent: this });

  public applyAfterSaleStore = new ApplyAfterSaleStore({ parent: this });

  public returnLogisticsStore = new ReturnLogisticsStore({ parent: this });

  public exportStore = new ExportStore({ parent: this });

  public timeInterval = null;

  public pageName = 'afterSale';

  // 查询售后单
  public querySaleOrder = () => {
    const params = this.programme.filterItems.params;

    // 只要选了售后状态就把tab选中
    this.activeStatus = params.status ? Number(params.status) : -1;
    const pageInfo = this.orderGridStore.pageInfo;

    try {
      // 处理申请时间
      if (params.applyTime) {
        const applyTimeObj = {
          applyStartTime: (params.applyTime as string).split(',')[0],
          applyEndTime: (params.applyTime as string).split(',')[1],
        };
        Object.assign(params, applyTimeObj);
        delete params.applyTime;
      }

      if (params.returnOrderNo) {
        Object.assign(params, { returnOrderNo: (params.returnOrderNo as string).split(',') });
      }
    } catch (err) {
      console.log(err);
    }

    // 增加分页信息
    Object.assign(params, {
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    });
    this.orderGridStore.loading = true;
    return request<PaginationData>({
      url: api.getAfterSaleOrder,
      method: 'POST',
      data: params,
    })
      .then((res) => {
        this.orderGridStore.list = res.data.list;
        this.orderGridStore.pageInfo = {
          page: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totalCount,
        };
      })
      .finally(() => {
        this.orderGridStore.loading = false;
      });
  };

  // 导出
  public exportOrder = (): void => {
    const selectedIds = this.orderGridStore.selectedIds;
    const params = this.programme.filterItems.params;
    const queryParamShow = this.programme.filterItems.translateParams.join(' ');

    if (params.applyTime) {
      const time = {
        applyStartTime: (params.applyTime as string).split(',')[0],
        applyEndTime: (params.applyTime as string).split(',')[1],
      };
      Object.assign(params, time);
      delete params.applyTime;
    }
    if (params.returnOrderNo) {
      Object.assign(params, { returnOrderNo: (params.returnOrderNo as string).split(',') });
    }
    const fileName = '售后单导出'; // `选款订单导出_${moment(Date.now()).format('YYYY-MM-DD hh:mm:ss')}`;
    if (selectedIds.length === 0) {
      Modal.confirm({
        title: '提示',
        content: '未选择数据将导出全部数据?',
        onOk: () => {
          this.exportStore.onShow(fileName, 'mall_return_order', '', {}, queryParamShow.trim(), params); // ids不传即代表导出全部数据
        },
      });
      return;
    }

    this.exportStore.onShow(fileName, 'mall_return_order', selectedIds.join(','), {}, queryParamShow.trim(), params); // 勾选部分数据
  };

  // 页码变化
  @action public onPageChange = (page: number, pageSize: number) => {
    this.orderGridStore.pageInfo = {
      ...this.orderGridStore.pageInfo,
      page,
      pageSize,
    };
    return this.querySaleOrder();
  };

  @observable public orderGridStore = new OrderGridStore({
    list: [],
    primaryKey: 'mallReturnOrderId',
    detailField: 'detailVoList',
    detailHeight: 100,
    detailKey: 'mallReturnOrderDetailId',
    itemHeaderColumns: ITEM_HEADER_COLUMNS(this),
    gridColumns: GRID_COLUMNS(this),
    onPageChange: (page: number, pageSize: number) => {
      this.orderGridStore.pageInfo = {
        ...this.orderGridStore.pageInfo,
        page,
        pageSize,
      };
      this.querySaleOrder();
    },
    showRefresh: true,

    // itemHeaderRightContent
    showCheckbox: true,
  });

  @observable public afterSaleStatusList = [];// 顶部售后状态

  public afterSaleStatusDict = {};// 售后状态字典

  @observable public activeStatus = -1;// 选中售后状态

  // 查询方案
  public programme: NormalProgramme = new NormalProgramme({
    filterItems: FILTER_ITEMS(this),
    count: 6,
    handleSearch: () => {
      const { pageSize } = this.orderGridStore.pageInfo;
      return this.onPageChange(1, pageSize);
    },
  });

  // 获取状态列表
  @action private getConfigStatus = () => {
    request<BaseData<ISaleStatus[]>>({ url: api.getStatusList })
      .then((res) => {
        const statusOptions = this.mapOptions(res.data, 'code', 'name');
        this.afterSaleStatusList = statusOptions;
        this.afterSaleStatusDict = res.data?.reduce((accu, cur) => {
          Object.assign(accu, { [cur.code]: cur.name });
          return accu;
        }, {});
        this.programme.filterItems.addDict({ status: statusOptions });
      });

    request<BaseData<ISaleStatus[]>>({ url: api.getAfterSaleReasons })
      .then((res) => {
        const reasonCodeOptions = this.mapOptions(res.data, 'code', 'name');
        this.programme.filterItems.addDict({ reasonCode: reasonCodeOptions });
      });
  };

  // 撤销申请
  public cancelApplication = (mallReturnOrderId: number) => {
    Modal.confirm({
      title: '提示',
      content: '是否确认撤销该条数据申请？',
      onOk: () => {
        return request<BaseData>({
          url: api.cancelAppli,
          method: 'GET',
          params: { mallReturnOrderId },
        })
          .then((res) => {
            message.success(res.info || '操作成功');

            // 刷新列表
            this.querySaleOrder();
          });
      },
    });
  };

  // 处理顶部tab选项变化
  @action public handleTabChange = (key: number) => {
    this.activeStatus = key;
    this.programme.filterItems.updateFilterItem([
      {
        field: 'status',
        value: key === -1 ? '' : key.toString(),
      },
    ]);

    // 重置表格组件页码
    this.orderGridStore.pageInfo.page = 1;
    this.programme.handleSearch();
  };

  // 处理选项
  private mapOptions = (data, key: string, val: string) => {
    return data?.map((item) => ({
      label: item[val],
      value: item[key],
    }));
  };
}
