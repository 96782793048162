import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import React from 'react';
import { api } from '../api';
import type { ICourierOption } from '../interface';
import type { Store } from '../store';

export class ReturnLogisticsStore {
  constructor(options) {
    this.parent = options.store;
  }

  public parent: Store;

  @observable public visible = false;// 弹窗是否可见

  public formRef = React.createRef<FormInstance>();

  @observable public courierOptions = [];// 快递选项

  public receivedAddress = '';// 退回地址
 
  public receivedMobile = '';// 退回用户电话

  public receivedUser = '';// 退回用户名

  public mallReturnOrderId = 0;// 售后单id

  public status = null;// 售后单id

  public auditTimeStr = '';// 售后单id

  // 打开退货物流弹窗
  @action public openModal = (receiverInfo: {
    receivedAddress: string;
    receivedMobile: string;
    receivedUser: string;
    mallReturnOrderId: number;
    status: number;
    auditTimeStr: string;
  }) => {
    this.visible = true;
    Object.assign(this, receiverInfo);
    this.getCourierOptions();
  };

  // 获取物流公司下拉框选项
  private getCourierOptions = () => {
    request<BaseData<ICourierOption[]>>({ url: api.getCourierList }).then((res) => {
      this.courierOptions = res.data.map((item) => ({
        label: item.courierName,
        value: `${item.courierName},${item.courierNo},${item.id}`,
      }));
    });
  };

  // 提交物流信息
  public submitCoureirInfo = () => {
    this.formRef.current?.validateFields().then((data) => {
      // 退货快递公司信息
      const courierCompanyArr = data.courierCompany.split(',');
      const courierInfo = {
        courierName: courierCompanyArr[0],
        courierNo: courierCompanyArr[1],
        courierId: courierCompanyArr[2],
      };

      // 合并售后快递单号和快递公司信息
      Object.assign(data, courierInfo, { mallReturnOrderId: this.mallReturnOrderId });
      delete data.courierCompany;

      request<BaseData>({
        url: api.fillLogistics,
        method: 'POST',
        data,
      }).then((res) => {
        message.success(res.data || '操作成功');
        this.closeModal();

        // 刷新列表
        this.parent.querySaleOrder();
      });
    });
  };
  
  // 关闭退货物流弹窗
  @action public closeModal = () => {
    this.visible = false;
    this.status = null;
    this.auditTimeStr = '';
    this.formRef.current?.resetFields();
  };
}
